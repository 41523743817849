import React from 'react';

export function FileItem({node, downloads}) {
    return (
        <span key={node.order + node.name}>
            <div>
                <span>{node.name}</span>
                <span>{node.variant}</span>
                <span>{node.vin}</span>
                <span>{node.order}</span>
                <span>{node.type}</span>
            </div>
            <div>
                <span>Downloads: </span>
                <ul>
                    {
                        downloads.map((download) => {
                            const downloadFileUrl = download.url;
                            const downloadFileType = download.fileType;

                            return (
                                <li key={downloadFileUrl}>
                                    <a href={downloadFileUrl} target="_blank">
                                        <em style={{
                                            color: '#e3001b'
                                        }}
                                            >{downloadFileType}</em> {download.name || 'Dokument'}
                                    </a>
                                </li>
                            );
                        })
                    }
                    {downloads.length === 0 &&
                    <span>&nbsp;-</span>
                    }
                </ul>
            </div>
        </span>
    )
}