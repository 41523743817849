import React from 'react';

import spinner from '../../assets/images/Spinner-1s-200px.svg';

import * as styles from './WaitABit.module.css';

WaitABit.propTypes = {};
WaitABit.defaultProps = {};

function WaitABit(props) {
  return (
    <section className={styles.WaitABit}>
      <div className={styles.waitingForData}>
        <img src={spinner} alt={'Loading'} />
      </div>
    </section>
  );
}

export default WaitABit;
