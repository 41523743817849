// extracted by mini-css-extract-plugin
export var Lagerliste = "Lagerliste-module--Lagerliste--iMxLX";
export var aisSearchBoxResetIcon = "Lagerliste-module--ais-SearchBox-resetIcon--XmqLS";
export var aisSearchBoxSubmit = "Lagerliste-module--ais-SearchBox-submit--1ARr6";
export var aisSearchBoxSubmitIcon = "Lagerliste-module--ais-SearchBox-submitIcon--5xUL8";
export var error = "Lagerliste-module--error--BavyZ";
export var errorMessage = "Lagerliste-module--errorMessage--+Jwnh";
export var fileType = "Lagerliste-module--fileType--3X8au";
export var header = "Lagerliste-module--header--6Rk2k";
export var headerContainer = "Lagerliste-module--headerContainer--6FfMp";
export var lagerliste = "Lagerliste-module--lagerliste--QN5tm";
export var searchForm = "Lagerliste-module--searchForm--Z6dHk";
export var toggleSearchLink = "Lagerliste-module--toggleSearchLink--ZMuDf";
export var waitingForData = "Lagerliste-module--waitingForData--DZIHf";