import React from 'react';
import { graphql } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import DefaultLayout from '../../layout/DefaultLayout';
import classnames from 'classnames';
//
import { auth } from '../../firebase-api';
import Lagerliste from '../../components/Lagerliste';

import * as styles from './lagerliste.module.css';
import withAuthorization from '../../components/Session/withAuthorization';

const LagerlistePage = (props) => {
  return (
    <DefaultLayout>
      <LagerListeContentWrapped {...props} />
    </DefaultLayout>
  );
};

const authCondition = (authUser) => !!authUser;

const LagerListeContent = ({ data, location }) => {
  const { pageData, siteData } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph } = pageData;

  const isBrowser = typeof window !== 'undefined';
  return (
    <>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      {/*
      <div className={classnames(styles.customerAreaContainer, 'defaultWidth')}>
        <h2>Lagerliste</h2>
        <button type="button" onClick={auth.doSignOut}>
          Ausloggen
        </button>
        {isBrowser && <Lagerliste />}
      </div>*/}
    </>
  );
};

const LagerListeContentWrapped = withAuthorization(authCondition)(LagerListeContent);

export default LagerlistePage;

export const LagerlistePageQuery = graphql`
  query LagerlistePageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageKundenbereich {
      content {
        title
        icon
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1300, height: 630)
          }
        }
      }
    }
  }
`;
