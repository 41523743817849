import React from 'react';

import spinner from '../assets/images/Spinner-1s-200px.svg';

import * as styles from './Lagerliste.module.css';
import { FileItem } from './FileItem';
import algoliasearch from 'algoliasearch/lite';
import PropTypes from 'prop-types';
import { Hits, InstantSearch, SearchBox } from 'react-instantsearch-dom';

class Lagerliste extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  state = {
    data: null,
    name: '',
    variant: '',
    vin: '',
    order: '',
    type: '',
    search: true,
    error: null,
  };

  componentDidMount() {
    const headers = new Headers();
    headers.append('Authorization', 'Basic bWVpbnRyYWt0b3I6bXQkRWludHJpVFQ2OQ==');

    // TODO: this is an ugly hack of a caching attempt
    // but I am in a rush :(
    if (window.lagerListenData) {
      this.setState({
        data: window.lagerListenData,
      });
    } else {
      fetch('https://meintraktor.cms.mangomedia.at/scripts/lagerliste/index.php', {
        method: 'GET',
        headers: headers,
      })
        .then((response) => {
          return response.json();
        })
        .then((resonseAsJson) => {
          window.lagerListenData = resonseAsJson;
          this.setState({
            data: resonseAsJson,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            error: error,
          });
        });
    }
  }

  filterData = (node) => {
    let matches = true;
    if (matches && this.state.name.length > 0) {
      matches = node.name && node.name.toLowerCase().indexOf(this.state.name.toLowerCase()) !== -1;
    }
    if (matches && this.state.variant.length > 0) {
      matches = node.variant && node.variant.toLowerCase().indexOf(this.state.variant.toLowerCase()) !== -1;
    }
    if (matches && this.state.vin.length > 0) {
      matches = node.vin && node.vin.toLowerCase().indexOf(this.state.vin.toLowerCase()) !== -1;
    }
    if (matches && this.state.order.length > 0) {
      matches = node.order && node.order.toLowerCase().indexOf(this.state.order.toLowerCase()) !== -1;
    }
    if (matches && this.state.type.length > 0) {
      matches = node.type && node.type.toLowerCase().indexOf(this.state.type.toLowerCase()) !== -1;
    }
    return matches;
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  toggleSearchMode = (e) => {
    this.setState({
      search: !this.state.search,
    });
    e.preventDefault();
  };

  sortLagerListeAlphabetically = (a, b) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  };

  render() {
    if (this.state.error !== null) {
      return (
        <section className={styles.Lagerliste}>
          <div className={styles.errorMessage}>
            <h3>Oops, das hätte nicht passieren sollen!</h3>
            <p>
              Leider ist es zu einem unerwarteten Fehler gekommen. Versuchen Sie es bitte etwas später noch einmal -
              falls es dann weiterhin nicht funktioniert kontakieren Sie uns bitte unter{' '}
              <a href="mailto:office@mangoart.at">office@mangoart.at</a> mit folgenden Informationen:{' '}
            </p>
            <ul>
              <li>Untenstehende Fehlermeldung bzw. Screenshot der aktuellen Seite</li>
              <li>Verwendeter Browser (Internet Explorer, Safari, Chrome, ...)</li>
              <li>URL die im Browser angezeigt wird</li>
              <li>Auf welcher Seite bzw. bei welcher Aktion der Fehler aufgetreten ist</li>
            </ul>
            <p className={styles.error}>{this.state.error.message}</p>
          </div>
        </section>
      );
    }

    if (this.state.data === null) {
      return (
        <section className={styles.Lagerliste}>
          <div className={styles.waitingForData}>
            <img src={spinner} alt={'Loading'} />
          </div>
        </section>
      );
    }

    const data = this.state.search
      ? this.state.data.filter(this.filterData).sort(this.sortLagerListeAlphabetically)
      : this.state.data.sort(this.sortLagerListeAlphabetically);

    let variants = [];
    if (this.state.search) {
      variants = this.state.data
        .map((node) => {
          return node.variant;
        })
        .filter((item, pos, self) => {
          return self.indexOf(item) == pos;
        });
    }

    Hit.propTypes = {
      hit: PropTypes.object.isRequired,
    };

    function Hit(props) {
      const lagerListenData = window.lagerListenData;

      const item = lagerListenData.find((node) => {
        return node.xls && node.xls.endsWith(props.hit.objectID);
      });
      if (item) {
        const downloads = [];
        if (item.pdf && item.pdf.length > 0) {
          downloads.push({
            url: item.pdf,
            name: 'Datenblatt',
            fileType: item.pdf.substring(item.pdf.lastIndexOf('.') + 1).toUpperCase(),
          });
        }
        return <FileItem node={item} downloads={downloads} />;
      }
      return null;
    }
    const searchClient = algoliasearch('12ZS74VIO4', 'b285b508d552a1bbbc2141c5228757de');

    return (
      <section className={styles.Lagerliste}>
        <div className={styles.lagerliste}>
          <InstantSearch searchClient={searchClient} indexName="lagerliste">
            <SearchBox
              translations={{
                placeholder: 'Suche nach Name, Variante, Fahrgestellnr., Auftrag, Art ',
              }}
            />
            <div className={styles.headerContainer}>
              <h3>Name:</h3>
              <h3>Variante:</h3>
              <h3>Fahrgestellnr.:</h3>
              <h3>Auftrag:</h3>
              <h3>Art:</h3>
            </div>
            <Hits hitComponent={Hit} />
          </InstantSearch>
        </div>
      </section>
    );
  }
}

export default Lagerliste;
