import React from 'react';

import AuthUserContext from './AuthUserContext';
import WaitABit from '../WaitABit';
import { firebase } from '../../firebase-api';
import * as routes from './routes';

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      firebase.auth.onAuthStateChanged((authUser) => {
        if (!condition(authUser)) {
          window.location = routes.SIGN_IN;
        }
      });
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) => {
            return authUser ? <Component {...this.props} /> : <WaitABit />;
          }}
        </AuthUserContext.Consumer>
      );
    }
  }

  return WithAuthorization;
};

export default withAuthorization;
